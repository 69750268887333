<template>
  <!-- Page content -->
  <div class="reset">
    <div class="reset-inner">
      <lang-switcher />
      <div class="reset-inner-form">
        <div class="logo">
          <img src="/img/elixio-connect.svg" alt="icon" />
        </div>
        <div class="form-inner">
          <base-alert type="danger" v-if="error">
            {{ error }}
          </base-alert>

          <base-alert type="success" v-if="resetPasswordEmailSent">
            {{ $t("COMMON.RESET_PASSWORD_SENT") }}
          </base-alert>

          <h1>{{ $t("COMMON.RESET_PASSWORD") }}</h1>

          <form
            class="needs-validation"
            @submit.prevent="handleSubmit()"
            v-if="!resetPasswordEmailSent"
          >
            <base-input
              name="Email"
              placeholder="Email"
              v-model="form.data.attributes.email"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.email" />

            <div class="submit">
              <base-button
                native-type="submit"
                :disabled="!form.data.attributes.email"
              >
                {{ $t("COMMON.SEND_PASSWORD_RESET_LINK") }}
              </base-button>
            </div>
          </form>
          <div class="return-login">
            <router-link :to="{ name: 'Login' }">
              <span>{{ $t("COMMON.RETURN_TO_LOGIN") }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseAlert } from "@/components";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import { LangSwitcher } from "@/components";

export default {
  layout: "AuthLayout",
  mixins: [formMixin],
  components: { ValidationError, BaseAlert, LangSwitcher },
  data() {
    return {
      form: {
        data: {
          type: "password-forgot",
          attributes: {
            email: "",
            redirect_url: process.env.VUE_APP_BASE_URL + "/password/email",
          },
        },
      },
      resetPasswordEmailSent: false,
      error: null,
    };
  },
  methods: {
    async handleSubmit() {
      this.error = null;
      try {
        await this.$store.dispatch("reset/forgotPassword", this.form.data);
        this.resetPasswordEmailSent = true;
      } catch (error) {
        this.error = this.$t("COMMON.RESET_PASSWORD_USER_NOT_FOUND");
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
